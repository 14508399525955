import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";


const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getMathWallet() ],
    []
  );

  // function toggleMenu() {
  //   const menu = document.getElementById("mobileNavContainer")!;
  //   menu.classList.toggle("open-menu");
  //   console.log("pressed");
  // }

  return (
    <div>
      {/*<div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button" >
          <img src="/icons/close.svg" alt="" onClick={toggleMenu}/>
        </div>
        <ul>
          <li>
            <img className="mobile-nav-logo" src="/img/logo.png" alt="" />
          </li>
          <li>
            <a href="/#link1" onClick={toggleMenu}>
              Link 1
            </a>
          </li>
          <li>
            <a href="/#link2" onClick={toggleMenu}>
              Link 2
            </a>
          </li>
          <li>
            <a href="/#link3" onClick={toggleMenu}>
              Link 3
            </a>
          </li>
          <li>
            <a href="/#link4" onClick={toggleMenu}>
              Link 4
            </a>
          </li>
          <li>
            <div className="social-icons">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
              <img className="nav-social" src="/icons/discord.svg" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>*/}
      <nav>
        <div className="nav-container">
          <a href="https://www.unapalomamuriendo.com">
            <img className="nav-logo" src="/media/logo.svg" alt="" />
          </a>
          {/*<a className="hide-800" href="/#link1">
            Link 1
          </a>
          <a className="hide-800" href="/#link2">
            Link 2
          </a>
          <a className="hide-800" href="/#link3">
            Link 3
          </a>
          <a className="hide-800" href="/#link4">
            Link 4
          </a>*/}

          {/*<div className="social-icons hide-800">*/}
          {/*<div className="social-icons">
            <a href="https://twitter.com/palomamuriendo" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/unapalomamuriendo" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/instagram.svg" alt="" />
            </a>
            <a href="https://discord.com/invite/V5pCh65WP4" target="_blank" rel="noreferrer">
              <img width="35px" className="nav-social" src="/icons/discord.svg" alt="" />
            </a>
          </div>*/}
        </div>
      </nav>
      <div className="content-wrapper">
          <header id="link1">
          {/*<header className="card" id="link1">*/}
            {/*<div style={{ padding: "0 24px 0 24px 0" }}>
              <h3 className="text-secondary-color">Welcome To</h3>
              <h1 className="pb-3">The Boiler Plate</h1>
              <p className="text-secondary-color">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                scelerisque ipsum non est porta mollis. Donec sapien sapien, dictum
                eget enim sed, hendrerit semper orci. Donec ante magna, consequat at
                eros ac, eleifend dictum sem. Nam vitae condimentum lorem.
                Vestibulum molestie dui turpis, tincidunt porta sem congue nec.
              </p>
            </div>*/}
            <div>
              <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                      
                        <Minter
                          candyMachineId={candyMachineId}
                          
                          connection={connection}
                          startDate={startDateSeed}
                          txTimeout={txTimeout}
                          rpcHost={rpcHost}
                        />
                      
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ThemeProvider>
            </div>
          </header>
          
          <div className="social-icons">
          {/*<div className="social-icons hide-800">*/}
            <a href="https://twitter.com/palomamuriendo" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/unapalomamuriendo" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/instagram.svg" alt="" />
            </a>
            <a href="https://discord.gg/V5pCh65WP4" target="_blank" rel="noreferrer">
              <img width="35px" className="nav-social" src="/icons/discord.svg" alt="" />
            </a>
          </div>

          <div className="buy-solana">
            <a href="https://widget.onramper.com/?onlyCryptos=SOL" target="_blank" rel="noreferrer" className="buy-solana-button">Buy Solana</a>
          </div>

          {/*<div id="link2" className="container">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac velit
            aliquet, semper sapien sed, ornare augue. Phasellus sed velit interdum,
            sagittis metus quis, facilisis lectus. Cras sollicitudin purus at magna
            eleifend maximus. Nulla nec nulla in nunc maximus viverra in at mauris.
            Fusce sodales dolor nisi, et vehicula orci porta id. In placerat nunc
            sed erat lacinia tincidunt. Interdum et malesuada fames ac ante ipsum
            primis in faucibus. Vestibulum commodo eget metus vitae tempus. Aliquam
            pharetra mi at efficitur accumsan. Curabitur venenatis libero a ex
            porttitor, at auctor turpis hendrerit. Nam commodo, risus non consequat
            pretium, erat ante auctor purus, a cursus dolor erat at velit. Maecenas
            dignissim, dolor sed laoreet aliquam, tortor lacus faucibus urna, eget
            mattis massa sem ac dui. Nam semper hendrerit interdum. Etiam at dictum
            nisi.
          </div>*/}

          {/*<div id="link3" className="container card">
            <h1 className="pb-3">Lorem ipsum</h1>
          </div>*/}

          {/*<div id="link4" className="container faq">
            <h1 style={{ padding: "0 0 24px 0" }}>FAQ</h1>
            <div>
              <h4>¿Qué es Crypto Bastards? </h4>
              <p>
                Crypto Bastards es un club internacional de crypto esports, también llamados juegos blockchain, fundado en 2021. El club cuenta con equipos en los juegos de Axie Infinity, Lightnite y Thetan Arena.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Qué puedo hacer en esta web?</h4>
              <p>
                Aquí podrás mintear el NFT que representa la membresía del club Crypto bastards a través de un wallet de Solana como 
                <span> </span><a href="https://phantom.app/download" target="_blank" rel="noreferrer">Phantom</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Para qué sirve la membresía de cryptobastards?</h4>
              <p>
                Los usuarios que tengan la membresía 2021 de Crypto Bastards tendrán acceso a actividades y regalos del club, así como participar en airdrops y colaboraciones exclusivas. En caso de realizarse eventos y nuevos mintings, aquellos con la membresía 2021 tendrán preferencia respecto al resto.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Qué es un NFT?</h4>
              <p>
                Sus siglas en inglés significan Non Fungible Token, o Tokens no fungibles, en castellano, que viene a decir que es un tipo de token digital único, un certificado digital de autenticidad que mediante la tecnología blockchain, la misma que se emplea en las criptomonedas, se asocia a un único archivo digital.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Qué significa mintear o minting?</h4>
              <p>
                Es el proceso de crear o generar un NFT.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Cuántos NFT de la membresía Crypto Bastards hay?</h4>
              <p>
                Hay un total de 500 NFT 2021 disponibles, todos con el mismo diseño y a la vez con un identificador único para cada NFT registrado en la blockchain de Solana. 50 membresías se reservan para la whitelist, y 450 para el minting público.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Cómo puedo conseguir el NFT de la membresía?</h4>
              <p>
                El minting se realizará el día 31 de enero, y solo necesitas tu billetera de Solana, como por ejemplo Phantom. No sirven billeteras o direcciones de exchanges. La web te permitirá conectar el wallet directamente para mintear con un par de clicks. 
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Qué coste tiene el NFT?</h4>
              <p>
                Mintear el NFT es gratis, solo deberás pagar la comisión de la blockchain por mintear en Solana, el coste puede variar según el día. Tener entre 0.1 y 0.01 SOL en tu billetera debería ser suficiente.
                <br/ >
                <br/ >
                Una vez minteado, el NFT se puede comercializar en mercados NFT de Solana como Magic Eden, y los usuarios son libres de venderlo si quieren y reciben ofertas por ello. El NFT cuenta con un royalty del 3%, es decir, Crypto Bastards recibirá una comisión del 3% del total pagado en ventas secundarias.
              </p>

              <hr />
            </div>

            <div>
              <h4>¿Cuándo es la fecha del minting?</h4>
              <p>
                <strong>31 de enero de 2022</strong>
                <br/ ><br/ >
                - De 19h a 21h UTC minting exclusivo para los usuarios en la whitelist<br/ >
                - 21h UTC minting público, hasta que se acaben los 400 NFTs
              </p>

              <hr />
            </div>

            <div>
              <h4>Puedes seguir a nuestro equipo en nuestras redes sociales: </h4>
              <p>
                -<span> </span><a href="https://twitter.com/crypto_bastards" target="_blank" rel="noreferrer">Twitter</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
                <br/ >
                -<span> </span><a href="https://t.me/+95zasupEXv5lMTc0" target="_blank" rel="noreferrer">Canal de Telegram</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
                <br/ >
                -<span> </span><a href="https://t.me/+LPH_y6NSf-1jODQ0" target="_blank" rel="noreferrer">Chat de Telegram</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              </p>
              <hr />
            </div>

            <div>
              <h4>Crypto Bastards en los medios:</h4>
              <p>
                -<span> </span><a href="https://es.beincrypto.com/beincrypto-entra-gamefi-mano-crypto-bastards/?utm_source=telegram&utm_medium=social" target="_blank" rel="noreferrer">Beincrypto</a><span> </span>
                <svg height="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              </p>

              <hr />
            </div>
          </div>*/}
      </div>
    </div>
  );
};

export default App;
